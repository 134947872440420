import { useState, useEffect } from 'react';
import { Routes, Route, useParams, Link } from "react-router-dom";

import { useTitle } from './Helpers'



function Home(props) {

	useTitle('')


	return (
		<div className='Home container px-4 d-flex justify-content-center align-items-center min-vh-100'>
			<div className='text-center pb-5'>
				<div className='display-1 fw-bold my-5'>🌻 Blooom</div>
				<div className='opacity-50 my-5'>Deploy your own NFT Collection in minutes.</div>
				<div className='my-5'>
					<Link className='btn btn-dark btn-lg rounded-pill fw-bold px-4' to='/collection/create'>Create Collection</Link>
					<div></div>
					<Link className='btn btn-link btn-sm text-reset fw-400 px-4 mt-3 opacity-75' to='/my-collections'>My Collections</Link>
				</div>
			</div>
		</div>
	)
}

export default Home;
