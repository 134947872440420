import { useState, useEffect } from 'react';
import { useAccount, useContractRead } from 'wagmi'

import { formatAddress, expandURL } from './Helpers'


function NFT(props) {

	let { address, isConnected } = useAccount()
	let { data: tokenURI } = useContractRead({ ...props.contractConfig, functionName: 'tokenURI', args: [props.tokenId] })
	let { data: owner } = useContractRead({ ...props.contractConfig, functionName: 'ownerOf', args: [props.tokenId] })

	const [metadata, setMetadata] = useState(null);

	useEffect(() => {
		fetchMetadata()
	}, [tokenURI])

	async function fetchMetadata() {
		if (tokenURI) {
			tokenURI = expandURL(tokenURI)
			// console.log(tokenURI)
			try {
				let response = await fetch(tokenURI)
				let data = await response.json()
				setMetadata(data)
			} catch (error) {
			}
		}
	}



	return <div className=''>
		<div>
			{metadata && <img src={expandURL(metadata.image)} className='img-fluid d-block shadow' />}
			{!metadata &&
				<div className="ratio ratio-1x1 shadow">
					<div className='d-flex align-items-center justify-content-center'>
						<div className="spinner-border opacity-50"></div>
					</div>
				</div>
			}
			<div className='d-flex justify-content-between mt-2'>
				<div className={'fw-600 mb-0 ' + (!metadata ? 'opacity-50' : '')}>{metadata ? metadata.name : '#' + props.tokenId}</div>
				{owner &&
					<div className='smaller text-break text-muted'>
						<span className='opacity-75'>Owner:</span> {owner == address ? <span className='fw-bold'>You 🎉</span> : formatAddress(owner)}
					</div>
				}
			</div>
		</div>
	</div>
}


export default NFT;
