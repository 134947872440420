import React from 'react';
import '@rainbow-me/rainbowkit/styles.css';
import { getDefaultWallets, RainbowKitProvider, lightTheme, darkTheme } from '@rainbow-me/rainbowkit';
import { configureChains, createClient, WagmiConfig, } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';

import App from './App';
import config from './config'



const hostname2chain = function() {
	if (window.location.hostname == 'localhost') return 'goerli'
	const subdomain = window.location.hostname.split('.').slice(0, -2).join('.')
	if (subdomain == '') return 'mainnet'
	return subdomain
}
let cc = Object.keys(config).filter(chainId => config[chainId].name == hostname2chain()).map(chainId => config[chainId].chain)
// console.log('setting network', window.location.hostname, cc);

const { chains, provider } = configureChains(
	// Object.keys(config).map(chainId => config[chainId].chain),
	cc,
	[publicProvider()]
);

const { connectors } = getDefaultWallets({
	appName: 'ΞLLISS',
	chains
});

const wagmiClient = createClient({
	autoConnect: true,
	connectors,
	provider
})



function RainbowKitApp() {
	return (
		<WagmiConfig client={wagmiClient}>
			<RainbowKitProvider chains={chains} theme={lightTheme({ accentColor: '#333' })}>
				<App />
			</RainbowKitProvider>
		</WagmiConfig>
	);
}

export default RainbowKitApp;
