import { useState, useEffect } from 'react';
import { Routes, Route, useParams, Link } from "react-router-dom";
import { useAccount, useNetwork, useContractRead, usePrepareContractWrite, useContractWrite, useWaitForTransaction } from 'wagmi'

import { formatAmount, useTitle } from './Helpers'
import { Page, Header, Title, StatusGate } from './CommonComponents'
import NFT from './NFT'

import contractArtifact from '../artifacts/contracts/BlooomCollectionTemplate.sol/BlooomCollectionTemplate.json'
import config from '../config'



function Collection() {

	let params = useParams();
	let collectionAddress = params.collectionAddress


	const [tokenCount, setTokenCount] = useState(20)


	const { address, isConnected } = useAccount()
	const { chain, chains } = useNetwork()

	// let chainConfig = chain && config[parseInt(chain.id)]
	// if (Object.keys(config).length == 1 && (Object.keys(config)[0] + '') == '1') {
	// 	chainConfig = config[1]
	// }
	let contractConfig = {
		// addressOrName: chainConfig && chainConfig.contractAddress,
		addressOrName: collectionAddress,
		contractInterface: contractArtifact.abi,
	}


	const { data: collectionName } = useContractRead({ ...contractConfig, functionName: 'name' })

	let { data: totalSupply, refetch: refreshTotalSupply } = useContractRead({ ...contractConfig, functionName: 'totalSupply' })
	totalSupply = totalSupply ? totalSupply.toNumber() : 0

	const { data: price } = useContractRead({ ...contractConfig, functionName: 'price' })
	// let price = priceRaw ? (priceRaw / 10 ** 18) : 0

	// console.log('collection', collectionAddress, collectionName, priceRaw, price);

	const { config: mintConfig, isError: mintError1 } = usePrepareContractWrite({ ...contractConfig, functionName: 'mint', args: [1], overrides: { value: price } })
	const { write: mint, isLoading: mintLoading, isSuccess: mintStarted, data: mintData, isError: mintError2 } = useContractWrite(mintConfig)
	const { isSuccess: mintComplete, isPending: mintPending, isError: mintError3 } = useWaitForTransaction({
		hash: (mintData && mintData.hash),
		onSettled(data, error) {
			refreshTotalSupply()
		},
	})
	const mintError = mintError1 || mintError2 || mintError3

	function mostRecentTokenIds() {
		let count = Math.min(totalSupply, tokenCount)
		return Array.apply(0, Array(count)).map((_, i) => totalSupply - i - 1)
	}

	const mintingDisabled = false


	useTitle(collectionName, [collectionName])

	return (
		<Page>
			<Header />

			<StatusGate status={!collectionName && 'Invalid collection'}>
				<div className='h1 mb-5 fw-bold text-center'>{collectionName}</div>

				<div className='my-5 text-center'>
					{!mintingDisabled && <>
						{isConnected &&
							<button className='btn btn-dark btn-lg rounded-pill fw-bold px-4' onClick={mint} disabled={mintLoading || (mintStarted && !mintComplete)}>
								{mintLoading && 'Waiting for approval...'}
								{(mintStarted && !mintComplete) && 'Minting...'}
								{!mintLoading && !(mintStarted && !mintComplete) && ('Mint for ' + (price > 0 ? formatAmount(price) : 'FREE'))}
							</button>
						}
						{mintComplete && <div className='mt-4'>🎉 Mint Successful 🎉</div>}
						{mintError && <div className='mt-4 text-danger'>Mint Error {mintError}</div>}
					</>}
					{mintingDisabled && <>
						<div className='h5 opacity-50'>Minting no longer availble</div>
						{/* {chain && chainConfig && chainConfig.contractAddress && parseInt(chain.id) == 1 &&
							<div className='mt-3'>
								<a className='mx-1' href={'https://www.gem.xyz/collection/' + chainConfig.contractAddress} target='_blank'>gem.xyz</a>
							</div>
						} */}
					</>}
				</div>

				<br />

				{totalSupply == 0 && <div className='text-center opacity-50'>No tokens have been minted yet.</div>}

				<div className='row align-top justify-content-center g-4 mb-5'>
					{totalSupply && mostRecentTokenIds().map(tokenId =>
						<div className='col-sm-6 col-lg-3' key={tokenId} >
							<NFT tokenId={tokenId} contractConfig={contractConfig} />
						</div>
					)}
				</div>
				{totalSupply > tokenCount &&
					<div className='text-center mb-5'>
						<div className='btn btn-sm btn-light text-uppercase rounded-pill px-3 fw-500 smaller' onClick={() => setTokenCount(tokenCount + 20)}>Show more</div>
					</div>
				}

				<br />

				<div className="my-5 py-5 small text-muted text-center">
					<div className='my-2'><span className='text-uppercase smaller'>Created with</span> <Link to="/" className="text-reset fw-bold text-decoration-none">🌻 Blooom</Link></div>
				</div>
			</StatusGate>

		</Page>
	);
}

export default Collection;
