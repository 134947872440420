import { useState, useEffect, useRef } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { ethers } from 'ethers'
import { useAccount, useNetwork } from 'wagmi'



function shorten(string, start, end) {
	return string.substring(0, start) + '…' + string.substring(string.length - end)
}
function formatAddress(address) {
	return shorten(address, 6, 4)
}
function formatAmount(amount) {
	return ethers.utils.formatEther(amount) + 'Ξ'
}

function etherscanLink(chain, path) {
	return chain.blockExplorers.default.url + path
}

const expandURL = (tokenURI) => {
	if (tokenURI.startsWith('ipfs:')) return 'https://ipfs.nftstorage.link/ipfs/' + tokenURI.substring(7)
	return tokenURI
}
const cid2http = (cid) => {
	return expandURL(cid2ipfs(cid))
}
const cid2ipfs = (cid) => {
	return 'ipfs://' + cid + '/'
}


const useLocalStorage = (key, defaultValue) => {
	const [value, setValue] = useState(() => {
		return JSON.parse(localStorage.getItem(key)) || defaultValue
	})
	useEffect(() => {
		localStorage.setItem(key, JSON.stringify(value))
	}, [key, value])
	return [value, setValue]
};
const useTitle = (title, deps = []) => {
	const chain = useChain()
	let titlePrefix = title ? `${title} • ` : ''
	let chainPostfix = chain && chain.id != 1 ? ` [${chain.name}]` : ''
	useEffect(() => { document.title = `${titlePrefix}Blooom${chainPostfix}` }, deps)
}
const useChain = () => {
	const { chain, chains } = useNetwork()
	// console.log('useChain', chain, chains);
	return chains[0]
}


function FormField({ title, type = '', value, onChange, controlClass, info, disabled, placeholder }) {
	return (
		<FormGroup title={title} info={info} >
			<Form.Control type={type} value={value} onChange={onChange} className={'fw-500 ' + controlClass} disabled={disabled} placeholder={placeholder} />
		</FormGroup>
	)
}

function FormGroup({ title, info, children }) {
	return (
		<Form.Group className="mb-4" >
			<Form.Label className='d-block text-uppercase small opacity-50'>{title}</Form.Label>
			{children}
			<Form.Text className="d-block opacity-50 mt-2">{info}</Form.Text>
		</Form.Group>
	)
}

export { shorten, formatAddress, formatAmount, etherscanLink, expandURL, cid2http, cid2ipfs, useLocalStorage, useTitle, useChain, FormField, FormGroup };
