import { useState, useEffect, useRef } from 'react';
import { Routes, Route, useParams, Link } from "react-router-dom";
import { useAccount, useContractRead, useBalance } from 'wagmi'
import { Table, Dropdown } from 'react-bootstrap';

import { shorten, formatAmount, etherscanLink, expandURL, useLocalStorage, useTitle, useChain } from './Helpers'
import { Page, Header, Title, StatusGate } from './CommonComponents'

import templateArtifact from '../artifacts/contracts/BlooomCollectionTemplate.sol/BlooomCollectionTemplate.json'
import config from '../config'



function MyCollections() {

	const [collections, setCollections] = useLocalStorage('my-collections', [])
	const { address, isConnected } = useAccount()


	const addCollectionAddress = () => {
		let address = window.prompt('Collection Contract Address')
		if (!address) return
		setCollections([...collections, { address }])
	}
	const removeCollection = (i) => {
		if (!window.confirm('Are you sure?')) return
		setCollections(collections.filter((_c, _i) => i != _i))
	}


	useTitle('My Collections')

	return (
		<Page>
			<Header />
			<Title>My Collections</Title>

			<StatusGate status={!isConnected && 'Connect wallet to view your collections'}>

				<StatusGate status={collections.length == 0 && 'No collections found'}>
					<div>
						<Table responsive className="">
							<thead>
								<tr className='smaller text-uppercase opacity-50'>
									<th className='fw-400'>Address</th>
									<th className='fw-400'>Name</th>
									<th className='fw-400'>Tokens</th>
									<th className='fw-400'>Price</th>
									<th className='fw-400'>Balance</th>
									<th className='fw-400'>Metadata URI</th>
									<th className='fw-400'></th>
								</tr>
							</thead>
							<tbody>
								{collections.map((c, i) =>
									<CollectionRow collection={c} key={i} onRemove={() => removeCollection(i)} />
								)}
							</tbody>
						</Table>
					</div>
				</StatusGate>

				<div className='mt-5'>
					<Link className='btn btn-dark btn-lgx rounded-pill fw-bold px-3 me-3' to='/collection/create'>Create Collection</Link>
					<button className='btn btn-link btn-sm rounded-pill fw-400 text-reset opacity-50' onClick={addCollectionAddress} >Add previously deployed collection</button>
				</div>

			</StatusGate>
		</Page>
	);
}


function CollectionRow({ collection, onRemove }) {

	const { address, isConnected } = useAccount()
	const chain = useChain()

	let contractConfig = {
		addressOrName: collection.address,
		contractInterface: templateArtifact.abi,
	}

	const { data: collectionName, isError, isSuccess, isFetching, isFetched } = useContractRead({ ...contractConfig, functionName: 'name' })
	const { data: symbol } = useContractRead({ ...contractConfig, functionName: 'symbol' })
	const { data: baseURI } = useContractRead({ ...contractConfig, functionName: 'baseURI' })

	const { data: totalSupply } = useContractRead({ ...contractConfig, functionName: 'totalSupply' })
	const { data: maxSupply } = useContractRead({ ...contractConfig, functionName: 'maxSupply' })

	const { data: price } = useContractRead({ ...contractConfig, functionName: 'price' })
	const { data: balanceData } = useBalance({ ...contractConfig })
	let balance = balanceData && balanceData.value

	const { data: owner } = useContractRead({ ...contractConfig, functionName: 'owner' })

	let owned = address && isSuccess && (owner || '').toLowerCase() == address.toLowerCase()
	// console.log(owned);

	return (
		<tr className='' >
			<td className='font-monospace smaller align-middle'>
				{owned && <Link to={"/collection/" + collection.address} className='text-reset '>{(collection.address)}</Link>}
				{!owned && <>
					<span className='opacity-75'>{collection.address}</span>
					{isSuccess && !owned && <i className='bi bi-exclamation-triangle-fill text-warning ms-1 ' title='Not Owned' />}
					{isFetching && <i className='bi bi-hourglass-split text-muted '></i>}
					{isError && <><i className='bi bi-exclamation-triangle-fill text-danger ms-1 '></i></>}
				</>}
			</td>
			{!owned && <>
				<td colSpan={5} />
			</>}
			{owned && <>
				<td className='fw-500'><Link to={"/collection/" + collection.address} className='text-reset text-decoration-none'>{collectionName}</Link> </td>
				<td className='small align-middle'>
					{totalSupply && totalSupply.toNumber()}
					{maxSupply && (' / ' + maxSupply)}
					{symbol && <span className=' opacity-50 ms-2'>{symbol}</span>}
				</td>
				<td className='small align-middle'>{price != null && formatAmount(price)}</td>
				<td className='small align-middle'>{balance > 0 && formatAmount(balance)}</td>

				<td className='smaller align-middle'>{baseURI != null && <a href={expandURL(baseURI)} target='_blank'>{baseURI.length > 40 ? shorten(baseURI, 17, 7) : baseURI}</a>}</td>

			</>}
			<td className='text-nowrap text-end' >
				<Dropdown align="end" className='d-inline-block'>
					<Dropdown.Toggle as='span' className=" fw-500" role='button' bsPrefix='x'>...</Dropdown.Toggle>
					<Dropdown.Menu className="shadow ">
						<Dropdown.Item className="small" href={etherscanLink(chain, '/address/' + collection.address)} target='_blank'>Etherscan <i className='bi bi-box-arrow-up-right text-muted small ms-1 ' /></Dropdown.Item>
						<Dropdown.Item className="small" onClick={() => onRemove && onRemove()}>Remove</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</td>
		</tr>
	)
}


export default MyCollections;
