import { useState, useEffect, useRef } from 'react';
import { Routes, Route, useParams, Link } from "react-router-dom";
import { ethers } from 'ethers'
import { useAccount, useNetwork, useContractRead, usePrepareContractWrite, useContractWrite, useWaitForTransaction } from 'wagmi'
import { Form, Row, Col } from 'react-bootstrap';

import { useTitle, FormField, FormGroup } from './Helpers'
import { Page, Header, Title, StatusGate } from './CommonComponents'
import UploadFolder from './UploadFolder'

import contractArtifact from '../artifacts/contracts/BlooomCollectionFactory.sol/BlooomCollectionFactory.json'
import config from '../config'



function CreateCollection() {

	const [nonce] = useState(Math.floor(Math.random() * Number.MAX_SAFE_INTEGER))
	const [name, setName] = useState('')
	const [symbol, setSymbol] = useState('')
	const [maxSupply, setMaxSupply] = useState(1000)
	const [maxPerWallet, setMaxPerWallet] = useState(10)
	const [price, setPrice] = useState(0.1)
	const [contentType, setContentType] = useState('upload-folder')
	const [baseURI, setBaseURI] = useState('')
	let [imagesCID, set_imagesCID] = useState()
	let [metadataCID, set_metadataCID] = useState()

	const { address, isConnected } = useAccount()
	const { chain, chains } = useNetwork()

	let chainConfig = chain && config[parseInt(chain.id)]
	// if (Object.keys(config).length == 1 && (Object.keys(config)[0] + '') == '1') {
	// 	chainConfig = config[1]
	// }
	let contractConfig = {
		addressOrName: chainConfig && chainConfig.contractAddress,
		// addressOrName: collectionAddress,
		contractInterface: contractArtifact.abi,
	}


	// const { data: implementation } = useContractRead({ ...contractConfig, functionName: 'implementation' })
	// const { data: owner } = useContractRead({ ...contractConfig, functionName: 'owner' })

	const { data: predictCollectionAddress } = useContractRead({ ...contractConfig, functionName: 'predictCollectionAddress', args: [address, nonce] })
	// console.log('factory:', nonce, predictCollectionAddress);

	const validInput = isConnected && name && symbol && maxSupply > 0 && maxPerWallet > 0 && baseURI

	let args = [
		nonce,
		name,
		symbol.toUpperCase(),
		maxSupply,
		maxPerWallet,
		ethers.utils.parseEther((price || 0).toString()),
		baseURI,
	]

	// const { config: createCollectionConfig, isError: createCollectionError1, error: createCollectionError11 } = usePrepareContractWrite({ ...contractConfig, functionName: 'createCollection', args: [1] })
	const {
		write: createCollection,
		isLoading: createCollectionLoading,
		isSuccess: createCollectionStarted,
		data: createCollectiontData,
		isError: createCollectionError2,
		error: createCollectionError22,
	} = useContractWrite({ ...contractConfig, functionName: 'createCollection', args: args })
	const {
		isLoading: createCollectionPending,
		isSuccess: createCollectionComplete,
		data: createCollectiontReceipt,
		isError: createCollectionError3
	} = useWaitForTransaction({
		hash: (createCollectiontData && createCollectiontData.hash),
		// onSettled(data, error) {
		// 	refreshTotalSupply()
		// },
	})

	let inProgress = createCollectionLoading || createCollectionStarted || createCollectionPending
	let createCollectionStatus = () => {
		if (createCollectionComplete) return 'Transaction complete: ' + createCollectiontData.hash
		if (createCollectionPending) return 'Transaction pending: ' + createCollectiontData.hash
		if (createCollectionStarted) return 'Transaction submitted...'
		if (createCollectionLoading) return 'Waiting for approval...'
		return ''
	}
	let createCollectionError = () => {
		if (createCollectionError2) return '' + createCollectionError22
		if (createCollectionError3) return 'error3'
		return ''
	}

	// console.log('createCollectionError22', createCollectionError22 ? createCollectionError22.name : '');


	useTitle('Create Collection')


	let contentTypes = [
		{ value: 'upload-folder', label: 'Upload Images to IPFS' },
		{ value: 'custom', label: 'Custom Metadata URI' },
	]
	function onUploaded(data) {
		set_imagesCID(data.imagesCID)
		set_metadataCID(data.metadataCID)
		setBaseURI(data.baseURI)
		console.log('onUploaded', data);
	}

	return (
		<Page>
			<Header />

			{!createCollectionComplete && <>
				<Title>Create Collection</Title>

				<div>
					<Row>
						<div className='col-md-12 mt-4'><FormField title='Collection Name' value={name} onChange={e => setName(e.target.value)} controlClass='form-control-lg fw-bold' disabled={inProgress} /></div>
					</Row>
					<Row>
						<div className='col-md-3'><FormField title='Collection Symbol' value={symbol} onChange={e => setSymbol(e.target.value)} controlClass='text-uppercase' disabled={inProgress} /></div>
						<div className='col-md-3'><FormField title='Max Supply' type='number' value={maxSupply} onChange={e => setMaxSupply(e.target.value)} disabled={inProgress} /></div>
						<div className='col-md-3'><FormField title='Max Per Wallet' type='number' value={maxPerWallet} onChange={e => setMaxPerWallet(e.target.value)} disabled={inProgress} /></div>
						<div className='col-md-3'><FormField title='Price (Ether)' type='number' value={price} onChange={e => setPrice(e.target.value)} disabled={inProgress} /></div>
					</Row>

					<Row>
						<div className='col-md-12 mt-5 mb-4x'>
							<FormGroup title='Content Type' >
								{contentTypes.map(x =>
									<Form.Check key={x.value} type='radio' name='contentType' className='fw-500x fs-5 d-inline-block me-5' role='button' value={x.value} label={x.label} id={x.value} checked={contentType == x.value} onChange={e => setContentType(e.target.id)} />
								)}
							</FormGroup>
						</div>
					</Row>
					<div className='bg-light rounded p-4'>
						{contentType == 'custom' &&
							<Row>
								<div className='col-md-12'><FormField title='Base URI' value={baseURI} onChange={e => setBaseURI(e.target.value)} disabled={inProgress} placeholder='ipfs://' info='Base URI must point to a folder that contains one metadata JSON file for each token: 0.json, 1.json, 2.json, etc...' /></div>
							</Row>
						}
						{contentType == 'upload-folder' &&
							<>
								<UploadFolder collectionName={name} imagesCID={imagesCID} metadataCID={metadataCID} onUriUpdated={setBaseURI} onUploaded={onUploaded} />
								{metadataCID && baseURI &&
									<div className=' mt-4'>
										<div className='text-uppercase small opacity-50 '>Base URI</div>
										<div className='fw-500'>{baseURI}</div>
									</div>
								}
							</>
						}
					</div>

					<div className='my-5 py-4'>
						<button className='btn btn-dark btn-lg rounded-pill fw-600 px-4 ' onClick={createCollection} disabled={!validInput || inProgress}>Create Collection {inProgress ? <div className="spinner-border spinner-border-sm opacity-50x ms-2" style={{ width: '1em', height: '1em' }}></div> : ''}</button>
					</div>

				</div>

				<div className='opacity-50'>{createCollectionStatus()}</div>
				<div className='text-danger text-break'>{createCollectionError()}</div>
			</>}

			{createCollectionComplete && <>
				<div className='display-4 mb-5'>Collection Created 🎉</div>
				<div><Link to={"/collection/" + predictCollectionAddress} className="h4 fw-bold text-decoration-none pe-3 my-3">Go to Collection <i className='bi bi-caret-right-fill'></i></Link></div>
			</>}

		</Page>
	);
}


export default CreateCollection;
