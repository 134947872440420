import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './App.css';
import Collection from './components/Collection'
import CreateCollection from './components/CreateCollection'
import MyCollections from './components/MyCollections'
import Home from './components/Home'



function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="collection/:collectionAddress" element={<Collection />} />
				<Route path="collection/create" element={<CreateCollection />} />
				<Route path="my-collections" element={<MyCollections />} />
				<Route path="*" element={<Home />} />
			</Routes>
		</BrowserRouter>

	);
}

export default App;
