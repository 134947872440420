import { Link } from "react-router-dom";
import { ConnectButton } from '@rainbow-me/rainbowkit';

import { useChain } from './Helpers'



function Page({ children }) {
	return <div className='container px-4 mb-5'>{children}</div>
}

function Header() {

	const chain = useChain()

	return <>
		<div className='d-flex align-items-center mt-3 mb-5'>
			<div className='h3 mb-0 fw-bold me-auto'><Link to="/" className="text-reset fw-bold text-decoration-none pe-3">🌻</Link></div>
			{chain && <div className='text-uppercase smaller opacity-25 fw-500 me-4'>{chain.name}</div>}
			<ConnectButton showBalance={false} />
		</div>
	</>
}

function Title({ children }) {
	return <div className='display-3 mb-5'>{children}</div>
}

function StatusGate({ status, children }) {
	return status ? <div><div className='py-5 small text-uppercase fw-500 text-center opacity-50 border rounded'>{status}</div></div> : children
}


export { Page, Header, Title, StatusGate };
