import { useState, useEffect, useRef } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { NFTStorage } from 'nft.storage'

import { FormField, FormGroup, expandURL, cid2http, cid2ipfs } from './Helpers'

const nftstorage = new NFTStorage({ token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweGMyNzBFZkU2YmEzZjg5NDVlMkQ1RTZmNEVDRTc2NDUwMzYyN2Q5ODMiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY2MjE2MDU2Nzc1NiwibmFtZSI6Ik1haW4ifQ.XgfdKGdMIpzOf4_YoHabYWvy96nRP3gKacXIxsk8GxI' })


function UploadFolder(props) {

	const [description, set_description] = useState('')

	let [imageFiles, set_imageFiles] = useState()
	let [metadataFiles, set_metadataFiles] = useState()

	let [uploadingImages, set_uploadingImages] = useState(props.metadataCID ? 'complete' : null)
	let [generatingMetadata, set_generatingMetadata] = useState(props.metadataCID ? 'complete' : null)
	let [uploadingMetadata, set_uploadingMetadata] = useState(props.metadataCID ? 'complete' : null)

	let [imagesCID, set_imagesCID] = useState(props.imagesCID)
	let [metadataCID, set_metadataCID] = useState(props.metadataCID)


	let onImageFolderSelected = (e) => {
		let files = e.target.files
		set_imageFiles(files)
		console.log('onImageFolderSelected', files.length)
	}
	let fileListSize = files => {
		let total = 0
		for (let i = 0; i < files.length; i++) {
			total += files[i].size
		}
		return total
	}

	let uploadToIPFS = async () => {
		console.log('uploadToIPFS', imageFiles.length)
		await uploadFiles(imageFiles, set_uploadingImages, cid => { imagesCID = cid; set_imagesCID(cid) })
		await generateMetadata()
		await uploadFiles(metadataFiles, set_uploadingMetadata, cid => { metadataCID = cid; set_metadataCID(cid) })
		// props.onUriUpdated && props.onUriUpdated(cid2ipfs(metadataCID))
		props.onUploaded && props.onUploaded({ imagesCID, metadataCID, baseURI: cid2ipfs(metadataCID) })

	}
	let uploadFiles = async (files, statusSetter, cidSetter) => {
		statusSetter(0)
		let totalSize = fileListSize(files)
		console.log(files, totalSize);
		let uploadedSize = 0
		let cid = await nftstorage.storeDirectory(files, {
			onStoredChunk: size => {
				uploadedSize += size
				statusSetter(uploadedSize / totalSize)
				console.log('onStoredChunk', size, uploadedSize / totalSize);
			}
		})
		cidSetter(cid)
		statusSetter('complete')
	}
	let generateMetadata = async () => {
		set_generatingMetadata('inprogress')
		let files = []
		for (let i = 0; i < imageFiles.length; i++) {
			let f = imageFiles[i]
			let nameComponents = f.name.split('.')
			let metadata = {
				name: `${props.collectionName} #${nameComponents[0]}`,
				description: description,
				image: cid2ipfs(imagesCID) + f.name,
			}
			let metadataFile = new File([JSON.stringify(metadata)], `${nameComponents[0]}.json`)
			files.push(metadataFile)
			// console.log(f, nameComponents, metadata, metadataFile)
		}
		metadataFiles = files
		set_metadataFiles(files)
		set_generatingMetadata('complete')
	}


	return (
		<div className=''>
			{uploadingImages === null &&
				<>
					<Row>
						<div className='col-md-12'>
							<FormGroup title='Image Folder' info='Folder must contain one image per token, starting with token 0. For example: 0.png, 1.png, 2.png, etc...' >
								<input className='form-control' type='file' directory="" webkitdirectory="" multiple onChange={onImageFolderSelected} disabled={!props.collectionName} />
							</FormGroup>
						</div>
						<div className='col-md-12'>
							<FormGroup title='Description' info='Will be added to generated metadata.' >
								<Form.Control as="textarea" rows={2} value={description} onChange={e => set_description(e.target.value)} />
							</FormGroup>
						</div>
					</Row>
					<div className='mb-3x'>
						<button className='btn btn-dark btn-lgx rounded-pill fw-500 px-3' onClick={uploadToIPFS} disabled={!(imageFiles && imageFiles.length > 0) || uploadingImages == 'inprogress'}>Upload to IPFS {uploadingImages == 'inprogress' ? <div className="spinner-border spinner-border-sm opacity-50x ms-2" style={{ width: '1em', height: '1em' }}></div> : ''}</button>
					</div>
				</>
			}
			{uploadingImages !== null && uploadingImages >= 0 && <div className='fw-500 '><i className='bi bi-hourglass-split text-muted me-1'></i> Uploading Images... {uploadingImages > 0 ? percent(uploadingImages) : ''}</div>}
			{uploadingImages == 'complete' && <div className='fw-500 '><i className='bi bi-check-circle-fill text-success me-1'></i> Images uploaded. <a href={cid2http(imagesCID)} target='_blank' className='smaller'>IPFS CID: {imagesCID}</a></div>}
			{generatingMetadata == 'complete' && <div className='fw-500 '><i className='bi bi-check-circle-fill text-success me-1'></i> Metadata generated.</div>}
			{uploadingMetadata !== null && uploadingMetadata >= 0 && <div className='fw-500 '><i className='bi bi-hourglass-split text-muted me-1'></i> Uploading Metadata... {uploadingMetadata > 0 ? percent(uploadingMetadata) : ''}</div>}
			{uploadingMetadata == 'complete' && <div className='fw-500 '><i className='bi bi-check-circle-fill text-success me-1'></i> Metadata uploaded. <a href={cid2http(metadataCID)} target='_blank' className='smaller'>IPFS CID: {metadataCID}</a></div>}
		</div>
	)
}

function percent(v) { return Math.round(v * 100) + '%' }


export default UploadFolder;
